<template>
  <div>
    <Home />
    <Benefits />
    <ManageControl />
    <ManageControlCard />
    <Alerty />
    <Reports />
  </div>
</template>

<script>
import Home from "./Home";
import Benefits from "./Benefits";
import ManageControl from "./ManageControl";
import ManageControlCard from "./ManageControlCard";
import Alerty from "./Alerty";
import Reports from "./Reports";
export default {
  name: "InfrastructureIndex",
  components: {
    Home,
    Benefits,
    ManageControl,
    ManageControlCard,
    Alerty,
    Reports
  }
};
</script>


