<template>
  <section class="home bg-img-1" id="home">
    <div class="bg-overlay"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="home-wrapper text-center">
            <h2 class="animated fadeInDown wow text-white" data-wow-delay=".1s">
              {{$t("Infrastructure and")}}
              <span class="text-colored">{{$t("Support")}}</span>
            </h2>
            <p
              class="animated fadeInDown wow text-light"
              data-wow-delay=".2s"
            >{{$t("We want to become a faithful ally of our clients for the correct operation and operations of their information systems.")}}</p>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Home"
};
</script>


